<template>
  <v-container>

    <span> Welcome to </span>
    <h3 class="mt-1 text-h3"> Haq.ai Challenges</h3>

    <div>
      <v-row v-if="$apollo.loading" class="">
        <v-col>
          <v-skeleton-loader
            class=""
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader
            class=""
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader
            class=""
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <div  v-for="(category, index) in categories" :key="index">
        <div v-if="filterCategory(category.node.pk).length">
          <h3 class="mt-5 text-uppercase">{{ category.node.title }} </h3>

          <div>
            <div class="mt-5" v-if="challenges.length">
              <splide :options="options">
                <splide-slide v-for="(challenge, index) in filterCategory(category.node.pk)" :key="index">
                  <v-card class="rounded-lg" width="350px">
                  <v-img :src="`${$s3path}/images/background-challenge.jpeg`" class="challenge-light">
                    <v-card-title class="white--text">{{ challenge.node.preTitle }}</v-card-title>
                    <v-card-text>
                      <span class="white--text text-h5">{{ challenge.node.title}}</span>
                    </v-card-text>
                  </v-img>
                    <v-card-actions class="mx-5">
                      <div class="text-center">
                        <div><span>{{ challenge.node.chapterSet.edgeCount }}</span></div>
                        <div>Chapters</div>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="text-center">
                        <div><span>{{ problemCount(challenge) }}</span></div>
                        <div>Problems</div>
                      </div>
                      <v-spacer></v-spacer>

                      <div>
                        <v-avatar color="white" size="100" class="mt-n15" @click="$router.push(`/challenge/overview/${challenge.node.pk}`)">
                          <v-progress-circular
                            :size="100"
                            width="13"
                          ><div class="">
                            <v-icon :color="theme ? 'grey' : ''">mdi-play</v-icon>
                          </div>
                          </v-progress-circular>
                        </v-avatar>

                        <div class="text-center">
                          <span>{{ percentage(challenge) }}%</span>
                        </div>
                      </div>
                      
                    </v-card-actions>
                </v-card>
                </splide-slide>
              </splide>
            </div>
          </div>
        </div>
        <div class="text-center mt-15" v-else>
          <hr class="">
          <div class="py-5">
            <span> No Challenges have posted yet. Coming Soon!</span>
          </div>
          <hr>
        </div>
      </div>

    </div>
    


  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { CHALLENGES, CATEGORY } from '@/queries/challenge';

export default {
  name: 'Challenges',
  mixins: [colorMxn],
  data() {
    return {
      options: {
        rewind:true,
        gap: '2rem',
        trimSpace: false,
        autoWidth: true,
        drag: true,
        breakpoint: {
          480: {
            perPage: 1,
          },
          768: {
            perPage: 2,
          },
          1024: {
            perPage: 3,
          },

        }
			},
      challenges: [],
      pageInfo:  {
        "hasNextPage": true,
        "hasPreviousPage": false,
        "startCursor": null,
        "endCursor": null,
      },
      categories: null,
    }
  },
  computed: {
  },
  apollo: {
    challenges: {
      query: CHALLENGES,
      variables: {
        "challengesFirst": 20,
        "challengesBefore": null,
        "challengesAfter": null,
      },
      update: (data) => {
        return data.challenges.edges
      }
    }
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    problemCount(challenge) {
      let result = 0;

      challenge.node.chapterSet.edges.forEach(element => {
          result += element.node.problemSet.edgeCount;
      });
      return result;
    },
    percentage(challenge) {
      const result = ( challenge.node.solvedProblems.edgeCount ? challenge.node.solvedProblems.edgeCount: 0) / this.problemCount(challenge) * 100
      return Math.round(result);
    },
    async fetchCategories() {
      this.$apollo.query({
        query: CATEGORY
      }).then((res) => {
        this.categories = res.data.categories.edges;
      })
        
    },
    filterCategory(pk) {
      return this.challenges.filter((challenge) => challenge.node.challengeCategory.pk === pk);
    }
  }
}
</script>

<style>
.challenge-light {
  background-color: blue;
  height: 270px;
}
</style>